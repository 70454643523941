import { Injectable } from '@angular/core';
import { DeviceService } from './device.service';
import { HttpClient, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { HttpResponse } from '@server/library/models';
import { GlobalStore } from '@app/state/store';
import { ToggleGlobalSpinnerAction } from '@app/state/actions';
import { Store } from '@ngrx/store';
import { selectUser } from '@app/state/store/global.store';
import { IJob, TaskTypes, Task, JobDestinationTypes } from '@libs/iso/core';
import { RtsService } from './rts.service';
import { InstallService } from './install.service';
import { take } from 'rxjs/operators';

declare var sjcl: any;

@Injectable()
export class ProxyService {
    constructor(
        private _deviceService: DeviceService,
        private _notificationService: NotificationService,
        private _store: Store<GlobalStore>,
        private _rtsService: RtsService,
        private _installService: InstallService,
        handler: HttpBackend
    ) {
        this._deviceService.getProxyLink().subscribe(res => {
            this._subdomain = res;
        });
        this._externalHttpClient = new HttpClient(handler);
    }

    private _externalHttpClient: HttpClient;

    private _subdomain: string;

    public async tryStartProxy(
        deviceId: string,
        installIds: Array<string>,
        entityId: string,
        entityName: string,
        port?: number,
        path?: string
    ): Promise<void> {
        const secret: Buffer = sjcl.random.randomWords(8);
        const secretHash: string = sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(secret));
        try {
            const jobId: string = (
                await this.dcaProxyJob(deviceId, installIds, entityId, entityName, port)
            )[0];
            const token: string = await this._deviceService
                .getProxyAuth(deviceId, jobId, secretHash)
                .toPromise();
            this._externalHttpClient
                .post(
                    `https://${deviceId}.${this._subdomain}/_pt_auth`,
                    `auth_token=${token}&client_secret=${sjcl.codec.hex.fromBits(secret)}`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json'
                    },
                    withCredentials: true
                }
                )
                .subscribe({
                    next: (resres: HttpResponse<any>): void => {
                        window.open(`https://${deviceId}.${this._subdomain}/${path || ''}`);
                        this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    },
                    error: (err: HttpErrorResponse): void => {
                        this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                        this._notificationService.error('Authorization Failed');
                    }
                });
        } catch (error) {
            this._store.dispatch(new ToggleGlobalSpinnerAction(false));
            this._notificationService.error(error.message);
        }
    }

    public async tryStartDcaProxy(
        installId: string,
        entityId: string,
        entityName: string,
    ): Promise<void> {
        const secret: Buffer = sjcl.random.randomWords(8);
        const secretHash: string = sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(secret));
        try {
            const jobId: string = (
                await this.dcaSelfProxyJob(installId, entityId, entityName)
            )[0];
            const token: string = await this._installService
                .getProxyAuth(installId, jobId, secretHash)
                .toPromise();
            this._externalHttpClient
                .post(
                    `https://${installId}.${this._subdomain}/_pt_auth`,
                    `auth_token=${token}&client_secret=${sjcl.codec.hex.fromBits(secret)}`,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Accept: 'application/json'
                        },
                        withCredentials: true
                    }
                )
                .subscribe({
                    next: (res: HttpResponse<any>): void => {
                        window.open(`https://${installId}.${this._subdomain}`);
                        this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                    },
                    error: (err: HttpErrorResponse): void => {
                        this._store.dispatch(new ToggleGlobalSpinnerAction(false));
                        this._notificationService.error('Authorization Failed');
                    }
                });
        } catch (error) {
            this._store.dispatch(new ToggleGlobalSpinnerAction(false));
            this._notificationService.error(error.message);
        }
    }

    // returns the id of the job
    private async dcaProxyJob(
        deviceId: string,
        installIds: Array<string>,
        entityId: string,
        entityName: string,
        port?: number
    ): Promise<any> {
        let installId;
        if (installIds.length === 0) {
            throw new Error('Device has no associated installs');
        } else {
            try {
                installId = await this._installService
                    .getMostRecentlyActive(installIds, true)
                    .toPromise();
            } catch (e) {
                throw new Error('Device has no installs that support Remote Technician right now');
            }
        }

        const user = await this._store
            .select(selectUser)
            .pipe(take(1))
            .toPromise();

        const params: Partial<IJob> = {
            entityId: entityId,
            installId: [installId],
            userId: user._id,
            metadata: {
                user: {
                    username: user.email,
                    firstname: user.firstName,
                    lastname: user.lastName
                },
                entity: {
                    name: entityName
                }
            },
            jobName: 'Open Device Webpage',
            jobDescription: 'Initializes a Remote Technician connection with a device',
            destination: JobDestinationTypes.Install,
            tasks: [
                new Task({
                    type: TaskTypes.CreateProxy,
                    payload: {
                        deviceId: deviceId,
                        port,
                    }
                })
            ]
        };

        return this._rtsService
            .createJob(
                params.entityId,
                params.installId,
                params.userId,
                params.metadata,
                params.jobName,
                params.jobDescription,
                params.destination,
                params.tasks
            )
            .toPromise();
    }

    // Like dcaProxyJob but instead of opening up a Remote Technician connection to a device, it opens
    // up the connection to the DCAs management interface.
    private async dcaSelfProxyJob(
        installId: string,
        entityId: string,
        entityName: string,
    ): Promise<any> {
        const user = await this._store
            .select(selectUser)
            .pipe(take(1))
            .toPromise();

        const params: Partial<IJob> = {
            entityId: entityId,
            installId: [installId],
            userId: user._id,
            metadata: {
                user: {
                    username: user.email,
                    firstname: user.firstName,
                    lastname: user.lastName
                },
                entity: {
                    name: entityName
                }
            },
            jobName: 'Open DCA Management Interface',
            jobDescription: 'Initializes a Remote Technician connection with the DCA management interface',
            destination: JobDestinationTypes.Install,
            tasks: [
                new Task({
                    type: TaskTypes.CreateProxy,
                    payload: { exposeSelf: true }
                })
            ]
        };

        return this._rtsService
            .createJob(
                params.entityId,
                params.installId,
                params.userId,
                params.metadata,
                params.jobName,
                params.jobDescription,
                params.destination,
                params.tasks
            )
            .toPromise();
    }
}
